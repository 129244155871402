import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import ModalCarousel from './modalCarousel';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import CarouselPlaceholder from 'static/images/gooding.png';
import YouTubePlayer from 'components/YoutubePlayer';
import './carousel.scss';

const Carousel = ({
  items,
  size,
  showDotsOnHover,
  fluidImages,
  title,
  className = '',
  video,
  showVideoFirst,
  videoThumbnail,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [modalCarouselIsOpen, setModalCarousel] = useState(false);
  const toggle = index => {
    setActiveSlide(index);
    setModalCarousel(!modalCarouselIsOpen);
  };

  const defaultSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    useTransform: false,
  };
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    // add the event listener to the component's root element
    const rootElement = document.getElementById('my-component');
    rootElement.addEventListener('contextmenu', handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  if (typeof window !== 'undefined') {
    document.addEventListener('contextmenu', e => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  const fullScreenCarouselSettings = {
    ...defaultSettings,
    variableWidth: true,
    className: 'slider variable-width',
  };

  const settings = size === 'sm' ? defaultSettings : fullScreenCarouselSettings;

  const smallCarouselSlides = fluidImages
    ? fluidImages.map((item, index) => (
        <div key={`carouselKey_${index}`} id="my-component">
          <Img
            style={{ 'pointer-events': 'none' }}
            fluid={item.fluid}
            alt={item.alt ?? item.title ?? title}
          />
        </div>
      ))
    : items.map((item, index) => (
        <div key={`carouselKey${index}`}>
          <img
            src={item.src || CarouselPlaceholder}
            alt={item.title}
            style={{ 'pointer-events': 'none' }}
          />
        </div>
      ));

  const fullScreenCarouselSlides = fluidImages
    ? fluidImages.map((item, index) => (
        <div
          key={`carouselKey${index}`}
          className="largeSlide"
          onClick={() => toggle(index)}
          role="presentation"
          onMouseDown={e => e.preventDefault()}
          style={{ 'pointer-events': 'none' }}
        >
          <GatsbyImage
            image={item.fluid}
            title={item.alt ?? item.title ?? title}
          />
        </div>
      ))
    : items.map((item, index) => (
        <div
          key={`carouselKey${index}`}
          className="largeSlide"
          onClick={() => toggle(index)}
          role="presentation"
        >
          <img
            src={item.src || CarouselPlaceholder}
            alt={item.title}
            style={{ 'pointer-events': 'none' }}
          />
        </div>
      ));

  const renderVideoSlide = () => (
    <div className="largeSlide">
      <YouTubePlayer
        className={`appCarousel__video`}
        url={video}
        controls={true}
      />
    </div>
  );

  return (
    <Fragment>
      <Slider
        {...settings}
        className={`appCarousel ${size !== 'sm' ? 'largeSlideContainer' : ''} ${
          showDotsOnHover ? 'hoverDots' : ''
        } ${className}`}
      >
        {showVideoFirst && video && renderVideoSlide()}
        {size === 'sm' ? smallCarouselSlides : fullScreenCarouselSlides}
        {!showVideoFirst && video && renderVideoSlide()}
      </Slider>
      <ModalCarousel
        toggle={toggle}
        isOpen={modalCarouselIsOpen}
        items={
          items?.length > 0
            ? showVideoFirst && video
              ? [{ url: video, fluid: { src: videoThumbnail } }, ...items]
              : !showVideoFirst && video
              ? [...items, { url: video, fluid: { src: videoThumbnail } }]
              : items
            : []
        }
        fluidImages={
          fluidImages?.length > 0
            ? showVideoFirst && video
              ? [{ url: video, fluid: { src: videoThumbnail } }, ...fluidImages]
              : !showVideoFirst && video
              ? [...fluidImages, { url: video, fluid: { src: videoThumbnail } }]
              : fluidImages
            : []
        }
        closeBtnType={`app-transparent-blue-button`}
        slideToIndex={activeSlide}
        video={video}
        showVideoFirst={showVideoFirst}
      />
    </Fragment>
  );
};

Carousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  size: PropTypes.oneOf(['sm', 'lg']),
};

Carousel.defaultProps = {
  size: 'sm',
  showDotsOnHover: false,
};

export default React.memo(Carousel);
