import _ from 'lodash';
import { contentTypes } from 'utils/contentTypeConstants';
import { TABS } from 'utils/tabsConstants';

const enabled = value => {
  const {
    auctionYear,
    liveAuctionVenue,
    auctionType,
    hasReservePrice,
    onlineBiddingAvailable,
    onlineAuctionName,
  } = value;
  return (
    !!_.head(auctionYear) ||
    !!_.head(liveAuctionVenue) ||
    !!_.head(onlineAuctionName) ||
    !!_.head(auctionType) ||
    hasReservePrice !== undefined ||
    onlineBiddingAvailable !== undefined
  );
};

const NUMBER_OF_MARKS = 30;
const MAX_ESTIMATE_PRICE = 3000000;

const getRangeData = estimates => {
  const marksPrices = [];
  const marksCounts = [];

  if (estimates.length === 0)
    return {
      initialData: { min: 0, max: 0 },
      marksCounts,
      marksPrices,
      step: 0,
      maxMark: 0,
    };

  const min = 0;
  const lowerBound = 30000;
  const higherBound = MAX_ESTIMATE_PRICE;
  const realMax = estimates[estimates.length - 1].value;

  // round the max price to the nearest multiple of lowerbound
  const tempMax =
    Math.ceil(estimates[estimates.length - 1].value / lowerBound) * lowerBound;

  const max =
    tempMax > higherBound
      ? higherBound
      : tempMax < lowerBound
      ? lowerBound
      : tempMax;
  const step = Math.floor((max - min) / NUMBER_OF_MARKS);
  const maxMark = step * NUMBER_OF_MARKS;

  let currentStep = step;
  let count = 0;
  let i = 0;
  while (currentStep <= maxMark && i < estimates.length) {
    if (estimates[i].value <= currentStep) {
      count += estimates[i].count;
      i++;
    } else {
      marksPrices.push(currentStep);
      marksCounts.push(count);
      count = 0;
      currentStep += step;
    }
  }
  // push count of the last step
  if (count !== 0) {
    marksPrices.push(currentStep);
    marksCounts.push(count);
  }
  //add counts of prices > max mark
  count = 0;
  while (i < estimates.length) {
    count += estimates[i].count;
    i++;
  }
  marksCounts.push(marksCounts.pop() + count);
  const initialData = { min, max: marksPrices[marksPrices.length - 1] };
  return { marksCounts, marksPrices, initialData, step, maxMark, realMax };
};

const PRIVATE_SALE_LISTING = 'Private Sale';

const getTag = vehicle => {
  if (vehicle?.auctionType === PRIVATE_SALE_LISTING) {
    if (!vehicle.salePrice || vehicle.askingPriceOverride) {
      return 'AVAILABLE';
    } else {
      return 'SOLD';
    }
  } else {
    const today = Date.now();
    if (
      vehicle.auctionStartDate <= today &&
      (vehicle.auctionEndDate >= today ||
        vehicle.upcomingAuctionEndDate >= today)
    )
      return 'AVAILABLE';
    if (vehicle.auctionStartDate > today) return 'UPCOMING';
    else if (
      !vehicle.salePrice &&
      (vehicle.askingPrice || vehicle.askingPriceOverride)
    )
      return 'AVAILABLE';
  }
  return '';
};

const getAuction = (vehicle, isNotTimestamp = false) => {
  const year = isNotTimestamp
    ? new Date(vehicle.auctionStartDate).getFullYear()
    : new Date(parseInt(vehicle.auctionStartDate)).getFullYear();
  switch (vehicle.auctionType) {
    case PRIVATE_SALE_LISTING:
      return PRIVATE_SALE_LISTING;
    default:
      return vehicle.auctionYear
        ? `${
            !Number.isNaN(vehicle.auctionYear) ? vehicle.auctionYear : ''
          } \xa0 | \xa0 ${vehicle.auctionName}`
        : `${!Number.isNaN(year) ? year : ''} \xa0 | \xa0 ${
            vehicle.auctionName
          }`;
  }
};

const getPrice = (vehicle, currency = 'USD') => {
  if (vehicle.salePrice)
    return `SOLD ${
      !vehicle.privateSalesPrice
        ? ` ${getPriceFormatted(vehicle.salePrice, currency)}`
        : ''
    }`;
  else {
    if (vehicle.askingPriceOverride) return `Price Available Upon Request`;
    if (vehicle.askingPrice)
      return `Asking Price - ${
        getPriceFormatted(vehicle.askingPrice, currency) ?? ''
      }`;
    if (vehicle.lowEstimate || vehicle.highEstimate || vehicle.uniqueEstimate) {
      const reserve = `${vehicle.hasReservePrice ? '' : 'Without Reserve'}`;
      const estimates =
        vehicle.uniqueEstimate ||
        getEstimates(vehicle.lowEstimate, vehicle.highEstimate, currency);
      return `${estimates} ${reserve && `| ${reserve}`}`;
    }
  }
  return '';
};

const getEstimates = (lowEstimate, highEstimate, currency) => {
  if (
    lowEstimate &&
    lowEstimate !== '0' &&
    highEstimate &&
    highEstimate !== '0'
  )
    return `${getPriceFormatted(lowEstimate, currency)} - ${getPriceFormatted(
      highEstimate,
      currency
    )}`;
  else if (lowEstimate && lowEstimate !== '0')
    return `${getPriceFormatted(lowEstimate, currency)}`;
  else if (highEstimate && highEstimate !== '0')
    return `${getPriceFormatted(highEstimate, currency)}`;
  else return '';
};

const getEstimatedValue = value => {
  if (value) {
    const splitValue = value.toString().split('$');
    return splitValue && splitValue.length > 1
      ? Number(splitValue[1].split('+')[0])
      : Number(splitValue[0].split('+')[0]);
  }
  return 0;
};
const getContentfulItemTitle = item => {
  if (!item || !item.__typename) return '';
  switch (item.__typename) {
    case contentTypes.VEHICLE:
      return `${
        item.modelYear ? `${item.textYear ? 'c.' : ''} ${item.modelYear}` : ''
      } ${item?.make ? item.make.name : ''} ${item.model ? item.model : ''}`;
    case contentTypes.AUTOMOBILIA:
      return item.title;
    default:
      return '';
  }
};

const getAlgoliaItemTitle = vehicle => {
  if (vehicle.itemType === 'Automobilia') return vehicle.title;
  else return `${vehicle.modelYear} ${vehicle.make} ${vehicle.model}`;
};

const getAuctionYear = subEvents => {
  let minStartDate = null;

  subEvents.forEach(subEvent => {
    if (subEvent && subEvent.startDate) {
      const startDate = Date.parse(subEvent.startDate);
      if (!minStartDate || startDate < minStartDate) minStartDate = startDate;
    }
  });
  return new Date(minStartDate).getFullYear();
};

const getPriceFormatted = (
  price,
  currency = 'USD',
  maximumFractionDigits = 0
) => {
  if (!price || price === '0') return '';
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits,
  }).format(price);
};
const changeActiveTab = (pageData, setFilters, selectedActiveTab) => {
  const queryFilter = {
    filtersInput: {
      make: [],
      auctionType: [],
      itemType: [],
      venue: [],
      auctionYear: [],
    },
    sortBy: 'ENDING_SOONEST',
  };
  if (selectedActiveTab === TABS.sameBrand) {
    queryFilter.filtersInput.make = pageData?.item?.make?.name;
    setFilters(queryFilter);
  } else if (selectedActiveTab === TABS.sameEra) {
    queryFilter.filtersInput.year = {
      start: `${pageData?.item?.modelYear - 10}`,
      end: `${pageData?.item?.modelYear + 10}`,
    };
    queryFilter.sortBy = 'YEAR_ASC';
    setFilters(queryFilter);
  } else if (selectedActiveTab === TABS.price) {
    queryFilter.filtersInput.estimate = {
      start: `${pageData?.lowEstimate}`,
      end: `${pageData?.highEstimate}`,
    };
    setFilters(queryFilter);
  } else {
    queryFilter.filtersInput.make = pageData?.item?.make?.name;
    queryFilter.filtersInput.year = {
      start: `${pageData?.item?.modelYear - 10}`,
      end: `${pageData?.item?.modelYear + 10}`,
    };
    queryFilter.sortBy = 'YEAR_ASC';
    setFilters(queryFilter);
  }
};
const getRelatedVehicles = (filteredRelatedVehicles, activeTab, pageData) => {
  if (
    (activeTab === TABS.sameEra || activeTab === TABS.all) &&
    filteredRelatedVehicles
  ) {
    const filteredVehicles = filteredRelatedVehicles.filter(
      item => item?.lotNumber !== pageData?.lotNumber
    );

    const filters = findKClosestElements(
      filteredVehicles,
      Number(pageData?.item?.modelYear),
      6,
      filteredVehicles?.length
    );
    return filters;
  } else if (activeTab === TABS.sameBrand || activeTab === TABS.price) {
    return filteredRelatedVehicles?.filter(
      item => item?.lotNumber !== pageData?.lotNumber
    );
  }
};
const findCrossOver = (arr, low, high, x) => {
  // Base cases
  if (Number(arr[high]?.modelYear) <= x)
    // x is greater than all
    return high;

  if (Number(arr[low]?.modelYear) > x)
    // x is smaller than all
    return low;

  // Find the middle point
  var mid = low + high; // 2 // low + (high - low)// 2

  // If x is same as middle element,
  // then return mid
  if (Number(arr[mid]?.modelYear) <= x && Number(arr[mid + 1]?.modelYear) > x)
    return mid;

  // If x is greater than arr[mid], then
  // either arr[mid + 1] is ceiling of x
  // or ceiling lies in arr[mid+1...high]
  if (Number(arr[mid]?.modelYear) < x)
    return findCrossOver(arr, mid + 1, high, x);

  return findCrossOver(arr, low, mid - 1, x);
};
const findKClosestElements = (arr, x, k, n) => {
  if (n > 6) {
    // Find the crossover point
    const array = [];

    var l = findCrossOver(arr, 0, n - 1, x);
    var r = l + 1; // Right index to search
    var count = 0; // To keep track of count of
    // elements already printed

    // If x is present in arr[], then reduce
    // left index. Assumption: all elements
    // in arr[] are distinct
    if (Number(arr[l]?.modelYear) == x) l -= 1;

    // Compare elements on left and right of crossover
    // point to find the k closest elements
    while (l >= 0 && r < n && count < k) {
      if (
        Math.abs(x - Number(arr[l]?.modelYear)) <=
        Math.abs(Number(arr[r]?.modelYear) - x)
      ) {
        // document.write(arr[l] + ' ');
        array.push(arr[l]);
        l -= 1;
      } else {
        // document.write(arr[r] + ' ')
        array.push(arr[r]);
        r += 1;
      }
      count += 1;
    }

    // If there are no more elements on right
    // side, then print left elements
    while (count < k && l >= 0) {
      // print(arr[l]);
      array.push(arr[l]);
      l -= 1;
      count += 1;
    }

    // If there are no more elements on left
    // side, then print right elements
    while (count < k && r < n) {
      // print(arr[r]);
      array.push(arr[r]);
      r += 1;
      count += 1;
    }
    return array;
  } else {
    return arr;
  }
};
const isBrowser = () => typeof window !== 'undefined';

export {
  enabled,
  getRangeData,
  getTag,
  getAuction,
  getPrice,
  getEstimatedValue,
  MAX_ESTIMATE_PRICE,
  getAlgoliaItemTitle,
  getContentfulItemTitle,
  getAuctionYear,
  getPriceFormatted,
  PRIVATE_SALE_LISTING,
  getEstimates,
  changeActiveTab,
  getRelatedVehicles,
  findCrossOver,
  findKClosestElements,
  isBrowser,
};
