import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import Slider from 'react-slick';
import Button from 'components/Button';
import YouTubePlayer from 'components/YoutubePlayer';
import Img from 'gatsby-image';

const ModalCarousel = ({
  toggle,
  isOpen,
  items,
  fluidImages,
  closeBtnType = 'app-transparent-white-button',
  slideToIndex,
  video,
  showVideoFirst,
}) => {
  const [innerHtml, setInnerHtml] = useState();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
    className: 'center',
    customPaging: function (i) {
      return fluidImages ? (
        <img
          src={`${fluidImages[i]?.fluid?.src}`}
          alt={`${fluidImages[i]?.title}`}
        />
      ) : (
        <img src={`${items[i]?.src}`} alt={`${items[i]?.title}`} />
      );
    },
    dotsClass: 'slick-dots slick-thumb',
    useTransform: false,
  };

  const [sliderCopy, setSliderCopy] = useState(null);

  useEffect(() => {
    sliderCopy &&
      sliderCopy.slickGoTo(!showVideoFirst ? slideToIndex : slideToIndex + 1);
  }, [slideToIndex, sliderCopy]);

  const renderVideoSlide = () => (
    <YouTubePlayer
      className={`appCarousel__video appCarousel__video--modal`}
      url={video}
      controls={true}
    />
  );

  const slides = fluidImages
    ? fluidImages.map((item, index) => (
        <div key={`carouselKeyModal${index}`} className="modalSliderContainer">
          {video && item.url ? renderVideoSlide() : <Img fluid={item.fluid} />}
        </div>
      ))
    : items.map((item, index) => (
        <div key={`carouselKeyModal${index}`} className="modalSliderContainer">
          {video && item.url ? (
            renderVideoSlide()
          ) : (
            <img src={item.src} alt={item.title} />
          )}
        </div>
      ));

  return (
    <Modal
      id="my-component"
      isOpen={isOpen}
      toggle={toggle}
      className="app-modal-carousel-container"
      backdropClassName="app-modal-carousel-backdrop"
    >
      <Button handleClick={toggle} type={closeBtnType}>
        Close
      </Button>
      <Slider
        {...settings}
        className="app-modal-carousel"
        ref={slider => setSliderCopy(slider)}
      >
        {slides}
      </Slider>
    </Modal>
  );
};

ModalCarousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ModalCarousel;
